export default [
  {
    value: "insertedAt",
    text: "Sent at",
    visible: true,
    width: 150,
  },
  {
    value: "from",
    text: "From",
    sortable: false,
    visible: true,
    width: 100,
  },
  {
    value: "fromName",
    text: "From Name",
    visible: true,
    width: 175,
  },
  {
    value: "to",
    text: "To",
    visible: true,
    width: 70,
  },
  {
    value: "toName",
    text: "To Name",
    visible: true,
    width: 175,
  },
  {
    value: "messageId",
    text: "Message ID",
    visible: false,
    width: 150,
  },
  {
    value: "type",
    text: "Type",
    visible: true,
    width: 60,
    align: "center",
  },
  {
    value: "text",
    text: "Text",
    visible: true,
    width: 450,
  },
  {
    value: "deliveryDatetime",
    text: "Delivered At",
    visible: true,
    width: 100,
  },
  {
    value: "status",
    text: "Status",
    visible: true,
    width: 80,
    align: "center",
  },
]
