<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="sms messages"
          :headers="headers"
          sort-by="inserted_at"
          item-key="inserted_at"
          :provider="provider"
          :filter-fields="filterFields"
        >
          <template #item.insertedAt="{ item }">
            {{ formatDate(item.insertedAt) }}
          </template>
          <template #item.fromName="{ item }">
            {{ item.fromName || "-" }}
          </template>
          <template #item.deliveryDatetime="{ item }">
            {{ formatDate(item.deliveryDatetime) || "-" }}
          </template>
          <template #item.type="{ item }">
            <v-chip
              v-if="item.type == 'Incoming'"
              x-small
              color="green"
              text-color="white"
            >
              {{ item.type }}
            </v-chip>
            <v-chip v-else x-small>
              {{ item.type }}
            </v-chip>
          </template>
          <template #item.text="{ item }">
            <EReadMore :content="item.text" :max-length="80" />
          </template>
          <template #item.status="{ item }">
            <span v-if="item.status == 'Received'">
              <v-icon small> fa-check-double </v-icon>
              {{ item.status }}
            </span>
            <span v-else-if="item.status == 'delivered'">
              <v-icon small> fa-check-double </v-icon>
              Delivered
            </span>
            <span
              v-else-if="item.status == 'Failed' || item.status == 'failed'"
            >
              <v-icon small> fa-times </v-icon>
              {{ item.status }}
            </span>
            <span v-else-if="item.status == 'accepted'">
              <v-icon small> fa-check </v-icon>
              Accepted
            </span>
            <span v-else-if="item.status == 'expired'">
              <v-icon small> fa-bell-slash </v-icon>
              Expired
            </span>
            <span v-else>
              <v-icon small> fa-clock </v-icon>
              Pending
            </span>
          </template>
        </ReportTable>
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
import MessageHeaders from "@/components/messages/messagesHeaders"
import filterFields from "@/components/messages/messagesSearchFilters"
import ReportTable from "@/components/ReportTable"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"

export default {
  name: "Messages",
  components: {
    ReportTable,
  },
  data() {
    return {
      headers: MessageHeaders,
      filterFields: {},
    }
  },
  head() {
    return {
      title: "Admin - SMS Messages",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useReportStore),
    provider() {
      return AdminApi.sms.getSms
    },
  },
  created() {
    this.filterFields = filterFields(this)
    this.reportStore.initialSearchParams = {
      ...this.reportStore.initialSearchParams,
      dateRange: [
        this.$moment().subtract(7, "d").format("YYYY-MM-DD"),
        this.formatDate(new Date(), "YYYY-MM-DD"),
      ],
    }
  },
}
</script>
