const smsTypes = [
  { name: "All", value: "" },
  { name: "Incoming", value: "incoming" },
  { name: "Outgoing", value: "outgoing" },
]

const smsStatusList = [
  { name: "All", value: "" },
  { name: "Received", value: "received" },
  { name: "Delivered", value: "delivered" },
  { name: "Pending", value: "pending" },
  { name: "Accepted", value: "accepted" },
  { name: "Failed", value: "failed" },
  { name: "Expired", value: "expired" },
  { name: "Unknown", value: "unknown" },
]

export default (self) => {
  return {
    insertedAt: {
      name: "dateRange",
      component: "DatePickerRangeSearchFilter",
      attributes: {
        datePickerAttrs: {
          value: [
            self.$moment().subtract(7, "d").format("YYYY-MM-DD"),
            self.formatDate(new Date(), "YYYY-MM-DD"),
          ],
        },
      },
    },
    from: {
      name: "fromNumber",
      component: "TextFieldSearchFilter",
    },
    fromName: {
      component: "TextFieldSearchFilter",
    },
    to: {
      name: "toNumber",
      component: "TextFieldSearchFilter",
    },
    toName: {
      component: "TextFieldSearchFilter",
    },
    type: {
      name: "smsType",
      component: "SelectSearchFilter",
      attributes: {
        items: smsTypes,
      },
    },
    text: {
      name: "smsText",
      component: "TextFieldSearchFilter",
    },
    deliveryDatetime: {
      component: "DatePickerSearchFilter",
      attributes: {
        textFieldAttrs: {
          placeholder: "Deliver At",
        },
        convertToIso: false,
      },
    },
    status: {
      name: "smsStatus",
      component: "SelectSearchFilter",
      attributes: {
        items: smsStatusList,
      },
    },
  }
}
